import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useColorMode } from "theme-ui";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useThemeColor = () => {
    const dispatch = useDispatch();
    const [colorMode, setColorMode] = useColorMode();

    useEffect(() => {
        if (!localStorage.getItem("theme-ui-color-mode")) {
            dispatch(setInnerLoaderTrue());
            setColorMode("dark");
            dispatch(setInnerLoaderFalse());
        }
    }, [dispatch]);

    const changeThemeMode = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        setColorMode((colorMode === "light") ? "dark" : "light");
        dispatch(setInnerLoaderFalse());

    }, [dispatch, colorMode, setColorMode]);

    return {
        colorMode,
        changeThemeMode,
    };
};