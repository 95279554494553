import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";

import NavigationWithBackButtonPageName from "../../Components/Shared/Navigation/NavigationWithBackButtonPageName";
import { useStatics } from "../../hooks/useStatics";

import { motion } from "framer-motion"
import { useLiveNotifications } from "../../hooks/useLiveNotifications";

const GetStarted = React.lazy(() => import("../../Pages/GetStarted/GetStarted"));

const LayoutWithBackButtonPageName = ({ children, pageTitle }) => {

    const { unseenNotifications, } = useLiveNotifications();

    // const location = useLocation();
    // const pageURL = location?.pathname.split("/")[1];

    // console.log(pageURL)

    const { getStartRef, setTrueStart } = useStatics();
    const navigate = useNavigate();

    useEffect(() => {
        if (getStartRef === null) {
            navigate("/");
        }
    }, [getStartRef, navigate]);

    return (
        <div>
            {
                getStartRef === null ?
                    <GetStarted setTrueStart={setTrueStart} />
                    :
                    <div className="home">
                        <motion.div
                            initial={{ opacity: 0, x: "-50%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: "50%" }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <div style={{ minHeight: "calc(100dvh - 63px)" }}>

                                <div className="sticky-top">
                                    <NavigationWithBackButtonPageName
                                        pageTitle={pageTitle}
                                        unseenNotifications={unseenNotifications}
                                    />
                                </div>
                                {children}
                            </div>
                        </motion.div>
                    </div>
            }
        </div>
    );
};

export default LayoutWithBackButtonPageName;