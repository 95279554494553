import React from 'react';
import { NavLink } from 'react-router-dom';
import { useThemeColor } from '../../../hooks/useThemeColor';

import { bottomNavList } from '../../../Data/BottomNavigationData';

import './BottomNavigation.css';

const BottomNavigation = () => {
    const { colorMode } = useThemeColor();
    return (
        <nav id="bottom-nav-area" className="row gx-0 py-2 d-flex justify-content-center align-items-center bottom-nav-area">
            <div className="col-12">
                <div className="row gx-0 d-flex justify-content-between align-items-center">
                    <div className="col-12 d-flex justify-content-around align-items-center">
                        {
                            bottomNavList?.slice(0, 6)?.map((item, index) =>
                                <NavLink key={item?.id} className={(navInfo) => (navInfo.isActive ? "nav-active" : "nav-link")}
                                    to={item?.link}
                                // to={item?.link + ((userInfo?.user?.username) ? ("?" + userInfo?.user?.username) : '')}
                                // onClick={() => item.id === 0 && setSelectedFragmentID(0)}
                                >
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={(colorMode === "light") ? item?.lightImage : item?.darkImage} alt={item?.navName} className="" height="23" width="23" />
                                    </div>
                                    <span className="active-bottom-nav-link mt-2">{item?.name}</span>
                                </NavLink>
                            )
                        }
                    </div>
                </div>
            </div>
        </nav >
    );
};

export default BottomNavigation;