// import type { Theme } from "theme-ui";
const theme = {
    initialColorMode: "dark",
    colors: {
        text: "black",
        background: "white",
        primary: "#1408e6",
        inverseText: "blue",
        modes: {
            dark: {
                text: "white",
                background: "var(--blackish-ash)",
                primary: "#8fceff",
                inverseText: "green",
            },
        },
    },
};

export default theme;