import React, { useEffect } from 'react';
import "./CustomSweetAlert.css";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useDispatch, useSelector } from 'react-redux';
import { closeCustomSweetAlert, customSweetAlertData } from '../../../Redux/customSweetAlertSlice';

import { useThemeColor } from '../../../hooks/useThemeColor';

const CustomSweetAlert2 = () => {

    const dispatch = useDispatch();
    const status = useSelector(customSweetAlertData);

    const { colorMode } = useThemeColor();

    const MySwal = withReactContent(Swal);

    const showSweetAlert2 = () => {
        MySwal.fire({
            title: <p style={{ color: (status?.type) === "success" ? "var(--light-green)" : (status?.type) === "error" ? "var(--red)" : "var(--red)" }}>{status?.message || `N/A`}</p>,
            // icon: (status?.type) === "success" ? "success" : (status?.type) === "error" ? "error" : "success",
            icon: status?.type || "success",
            showConfirmButton: false,
            background: (colorMode === "dark") ? "var(--dark)" : (colorMode === "light") ? "var(--white)" : "var(--red)",
            timer: 3000,
            willClose: () => {
                // setTimeout(() => {
                dispatch(closeCustomSweetAlert());
                // }, 0);
            },
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            closeButtonHtml: '&times;',
            didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                // MySwal.showLoading()
            },
        }).then(() => {
            return dispatch(closeCustomSweetAlert());
        })
    }

    useEffect(() => {
        if (status?.show) {
            showSweetAlert2();
        }
    }, [status?.show]);

    return (
        <>

        </>
    );
};

export default CustomSweetAlert2;