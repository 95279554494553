import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { userLoading } from "../../Redux/userSlice";
import { userKey } from "../../App";
import Loader from "../../Components/Shared/Loader/Loader";

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const loading = useSelector(userLoading);
    // const user = useSelector(userDetail);
    // console.log(user);

    if (loading === "loading") {
        return (
            <Loader show={true} />
        )
    }

    let stringValue = localStorage.getItem(userKey);
    if (stringValue) {
        return children;
    }

    // if (user.is_login) {
    // }

    return (<Navigate to="/login" state={{ from: location }} replace></Navigate>);
};

export default PrivateRoute;