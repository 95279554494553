import React from "react";

const LauncherErrorAlert = ({ close, item }) => {
    // console.log(item);
    return (
        <>
            <p className=""
                style={{ color: item?.color || `transparent` }}
            >
                {item?.text || `N/A`}
            </p>
        </>
    );
};

export default LauncherErrorAlert;