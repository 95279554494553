import React from "react";
import Navigation from "../../Components/Shared/Navigation/Navigation";
import BottomNavigation from "../../Components/Shared/BottomNavigation/BottomNavigation";
import { useStatics } from "../../hooks/useStatics";

import { motion } from "framer-motion"

const GetStarted = React.lazy(() => import("../../Pages/GetStarted/GetStarted"));

const BaseLayout = ({ children }) => {

    const { getStartRef, setTrueStart } = useStatics();

    return (
        <div>
            {
                getStartRef === null ?
                    <GetStarted setTrueStart={setTrueStart} />
                    :
                    <div className="home">
                        <motion.div
                            initial={{ opacity: 0, x: "-50%" }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: "50%" }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <div style={{ minHeight: "calc(100dvh - 63px)" }}>

                                <div className="sticky-top">
                                    <Navigation
                                    // parentUrl={parentUrl}
                                    // setSelectedFragmentID={setSelectedFragmentID}
                                    />
                                </div>
                                {children}
                            </div>
                        </motion.div>


                        <div className="d-none">
                            {/* <h6>{"Bottom Navigation"}</h6> */}
                            <BottomNavigation
                            // setSelectedFragmentID={setSelectedFragmentID}
                            />
                        </div>
                    </div>
            }
        </div>
    );
};

export default BaseLayout;