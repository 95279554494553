
// Function to add cache data
export const addDataIntoCache = (cacheName = null, url = null, response = null) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));

    if ('caches' in window) {
        // Opening given cache and putting our data into it
        caches.open(cacheName).then((cache) => {
            cache.put(url, data);
            // alert('Data Added into cache!')
        });
    }
};

// Function to get cache data
export const getCacheData = async (cacheName, url) => {
    if (typeof caches === 'undefined') return null;

    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
        return null
    } else {
        let data = await cachedResponse.json();
        // console.log(data);
        return data;
    }
};

export const deleteSpecificCacheData = (cacheName) => {
    if ("caches" in window) {
        caches.delete(cacheName).then(function (res) {
            // alert(cacheName);
            return res;
        });
    }
}