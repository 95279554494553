import React from "react";
import "./Promotionals.css";

import { useStatics } from "../../hooks/useStatics";
import { useThemeColor } from "../../hooks/useThemeColor";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";

import { rootUrl } from "../../App";

const androidIconLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/footer/device-system/android-icon-light.svg";

const chromeMonitorIconLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/footer/device-system/chrome-monitor-icon-light.svg";


const Promotionals = ({ installableBtnRef }) => {
    const { sliderImgList, } = useStatics();
    const { colorMode } = useThemeColor();

    // console.log(sliderImgList);

    const requestForInstallApp = () => {
        if (installableBtnRef.current) {
            installableBtnRef.current.prompt();

            (installableBtnRef.current.userChoice).then((choiceResult) => { // install button function
                // console.log(choiceResult);
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                    installableBtnRef.current = null;
                } else {
                    console.log("User dismissed the A2HS prompt");
                    alert("User dismissed installation");
                }
            });
        } else {
            return;
        }
    }

    return (
        <div className={(sliderImgList?.length > 0) ? "promotionals row gx-0 d-flex justify-content-center align-items-center" : "d-none"} style={{ minHeight: "100dvh", }}>
            <div
                className="col d-flex justify-content-center align-items-center"
            >
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="promotionalsSlider pb-0 pb-md-0"
                >
                    {
                        sliderImgList?.map((item, index) =>
                            <SwiperSlide key={item?.id}>
                                <div className="row gx-0 d-flex flex-column flex-md-row justify-content-center align-items-center justify-content-md-center align-items-md-center">
                                    <div className="col-11 col-md-6 d-flex justify-content-center align-items-center pb-3 pb-md-3">
                                        <img src={rootUrl + item?.image} alt={item?.alt_text} className="sliding-image"
                                        />
                                    </div>
                                    <div className="col-11 col-md-6 d-flex justify-content-center align-items-center">
                                        <div className="row gx-0 d-flex justify-content-center align-items-center">
                                            <div className="col-12 col-md-11 d-flex flex-column justify-content-center align-items-center py-4 py-md-4"
                                                style={{ color: "var(--white)", }}
                                            >
                                                <h2 className="fw-bold text-uppercase">{item?.title}</h2>
                                                <h4 className="fw-bold text-uppercase pb-3 pb-md-4" style={{ color: "" }}>{item?.sub_title}</h4>
                                                <p className="text-center"><small>{item?.description}</small></p>
                                            </div>

                                            <div className="col-11 d-none d-md-flex flex-column justify-content-center align-items-center py-4 py-md-4"
                                                style={{ color: "var(--golden)", }}
                                            >
                                                <p className="mb-3">
                                                    <small>
                                                        {"Download our APP version now!"}
                                                    </small>
                                                </p>

                                                <div className="d-flex justify-content-center align-items-center mb-3">
                                                    <button
                                                        onClick={requestForInstallApp}
                                                        className="mx-0 px-0"
                                                        disabled={!installableBtnRef.current}
                                                        style={{ border: "none", outline: "none", boxShadow: "none", backgroundColor: "transparent", }}
                                                    >
                                                        <img src={(colorMode === "light") ? androidIconLight : androidIconLight} height={""} width={"50"} alt="android" />
                                                    </button>
                                                    <button
                                                        onClick={requestForInstallApp}
                                                        className="mx-0 px-0 ps-1"
                                                        disabled={!installableBtnRef.current}
                                                        style={{ border: "none", outline: "none", boxShadow: "none", backgroundColor: "transparent", }}
                                                    >
                                                        <img src={(colorMode === "light") ? chromeMonitorIconLight : chromeMonitorIconLight} height={""} width={"50"} alt="chrome-monitor" />
                                                    </button>
                                                </div>

                                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                                    <button onClick={requestForInstallApp}
                                                        // disabled={!installableBtnRef.current}
                                                        type="button" className="text-uppercase btn golden-button py-2 py-md-2 w-100">{"Download"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>
            <div className="col-11 d-flex d-md-none flex-column justify-content-center align-items-center py-4 py-md-4"
                style={{ color: "var(--golden)", }}
            >
                <p className="mb-3">
                    <small>
                        {"Download our APP version now!"}
                    </small>
                </p>

                <div className="d-flex justify-content-center align-items-center mb-3">
                    <button
                        onClick={requestForInstallApp}
                        className="mx-0 px-0"
                        disabled={!installableBtnRef.current}
                        style={{ border: "none", outline: "none", boxShadow: "none", backgroundColor: "transparent", }}
                    >
                        <img src={(colorMode === "light") ? androidIconLight : androidIconLight} height={""} width={"50"} alt="android" />
                    </button>
                    <button
                        onClick={requestForInstallApp}
                        className="mx-0 px-0 ps-1"
                        disabled={!installableBtnRef.current}
                        style={{ border: "none", outline: "none", boxShadow: "none", backgroundColor: "transparent", }}
                    >
                        <img src={(colorMode === "light") ? chromeMonitorIconLight : chromeMonitorIconLight} height={""} width={"50"} alt="chrome-monitor" />
                    </button>
                </div>

                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                    <button onClick={requestForInstallApp}
                        // disabled={!installableBtnRef.current}
                        type="button" className="text-uppercase btn golden-button py-2 py-md-2 w-100">{"Download"}</button>
                </div>
            </div>
        </div >
    );
};

export default Promotionals;