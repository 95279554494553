import React, { useEffect } from "react";
import "./SuccessDetails.css";

// import { createPortal } from "react-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useDispatch, useSelector } from "react-redux";

import { useThemeColor } from "../../../../hooks/useThemeColor";

import { closeDetailsSweetAlert, detailsSweetAlertData } from "../../../../Redux/detailsSweetAlertSlice";
import DetailsDataShow from "./DetailsDataShow";

// import DetailsDataShow from "./DetailsDataShow";


const SuccessDetails = () => {
    const dispatch = useDispatch();
    const status = useSelector(detailsSweetAlertData);

    const { colorMode } = useThemeColor();

    const MySwal = withReactContent(Swal);

    const showDetailsSweetAlert2 = () => {
        MySwal.fire({
            title: <p className="mb-0 custom-sweet-alert-title" style={{
                color:
                    (status?.type) === "success" ? "var(--light-green)" :
                        (status?.type) === "error" ? "var(--red)" :
                            (status?.type) === "warning" ? "var(--golden)" :
                                (status?.type) === "info" ? "var(--blue)" :
                                    "transparent"
            }}>{status?.message || `N/A`}</p>,
            icon: status?.type || "success",
            showConfirmButton: false,
            background: (colorMode === "dark") ? "var(--dark)" : (colorMode === "light") ? "var(--white)" : "var(--red)",
            // timer: 3000,
            willClose: () => {
                // setTimeout(() => {
                dispatch(closeDetailsSweetAlert());
                // }, 0);
            },
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            closeButtonHtml: "&times;",
            didOpen: () => {
                // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                // MySwal.showLoading()
            },
            html:
                <div>
                    {
                        (status?.item?.head) &&
                        <div style={{
                            color:
                                (status?.type) === "success" ? "var(--light-green)" :
                                    (status?.type) === "error" ? "var(--red)" :
                                        (status?.type) === "warning" ? "var(--golden)" :
                                            (status?.type) === "info" ? "var(--blue)" :
                                                "transparent"
                        }}>
                            <h2 className="mt-0 custom-sweet-alert-sub-title">{status?.item?.head?.Amount}</h2>
                        </div>
                    }
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                            borderTop: "1px dashed var(--gray)",
                            color: (colorMode === "light") ? "var(--dark)" : (colorMode === "dark") ? "var(--white)" : "var(--red)",
                        }}
                    >
                        {
                            (status?.item?.body) &&
                            <DetailsDataShow
                                item={status?.item?.body}
                            />
                        }
                    </div>
                </div>
        }).then(() => {
            return dispatch(closeDetailsSweetAlert());
        })
    }


    useEffect(() => {
        if (status?.show) {
            showDetailsSweetAlert2();
        }
    }, [status?.show]);
    // if (status?.show) {
    //     showDetailsSweetAlert2();
    // }

    return (
        <>

        </>
    );
};

export default SuccessDetails;