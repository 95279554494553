import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const backend = new Backend({
    // path where resources get loaded from
    loadPath: '/locales/{{lng}}/{{ns}}.json',
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(backend)
    .init({
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
