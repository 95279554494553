import { userKey } from "../App";
import useFetchData from "./useFetchData";

export const useGameItems = (type) => {
    // if (type === null) return;
    let stringValue = localStorage.getItem(userKey);
    let value = JSON.parse(stringValue);

    const requestOptions = {
        method: 'GET',  // Adjust the HTTP method as needed
    };

    if (value?.value) {
        requestOptions.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Token ${value?.value}`
        };
    }

    const state = useFetchData(`/game/getGameListByType/${type}/`, requestOptions);
    // console.log(state?.data?.data);
    return {
        allGames: state?.data?.data
    }
};

